<template>
  <div class="videoMonitor__wrapper">
    <div class="breadcrumb"></div>
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="设备号/通道编码">
                <el-input
                  v-model="formInline.monitorEquipmentChannelCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_parking_lot')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                v-if="$route.meta.authority.button.add"
                type="primary"
                icon="el-icon-plus"
                @click="$router.push('/videoMonitorEdit')"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <el-button type="info" icon="el-icon-download" @click="openMuli">{{ $t('button.import') }}</el-button>
            </div>
          </div>

          <!-- <el-form-item label=""> -->

          <!-- <el-button
              type="primary"
              icon="el-icon-plus"
              v-if="$route.meta.authority.button.add"
              @click="$router.push('/addVideoMonitorEquip')"
              >{{ $t('button.addto') }}</el-button
            > -->
          <!-- </el-form-item> -->
        </el-form>
      </div>
      <upload-file
        ref="upload"
        :uploadData="uploadData"
        @success="success"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
      ></upload-file>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command="a">查看监控</el-dropdown-item>
                  <el-dropdown-item command="b">编辑</el-dropdown-item>
                  <el-dropdown-item command="c">删除</el-dropdown-item> -->
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.monitor"
                    >查看监控</el-dropdown-item
                  >
                  <el-dropdown-item command="b" v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c" v-if="$route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <video-monitor
      ref="videoMonitor"
      :passageway-list="passagewayList"
      :isShowChange="isShowChange"
      @change="videoChange"
      v-if="isShowVideoDialog"
      :isShowPassageway="false"
      :title="title"
      :isPlayBack="isPlayBack"
      @close="isShowVideoDialog = false"
    ></video-monitor>
  </div>
</template>

<script>
/* eslint-disable */
import VideoMonitor from "@/components/video-monitor/";
import uploadFile from "@/components/uploadFile/";
export default {
  components: {
    VideoMonitor,
    uploadFile,
  },
  data() {
    return {
      passagewayList: [],
      uploadUrl: "/acb/2.0/monitorEquipmentChannel/monitorEquipmentChannel/import",
      modelUrl: "/acb/2.0/monitorEquipmentChannel/monitorEquipmentChannel/downloadExcelTemplate",
      uploadData: "",
      isShowVideoDialog: false,
      title: "",
      isShowChange: false,
      loading: "",
      modelvalue: "",
      temParkData: {},
      total: 0,
      pageSize: 15,
      page: 1,
      tenantList: [],
      formInline: {
        parkId: "",
        monitorEquipmentChannelCode: "",
        operationId: "",
      },
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.Belonging_parking_lot"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "",
        },
        {
          prop: "monitorEquipmentChannelName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "monitorEquipmentName",
          label: this.$t("list.Belonging_NVR"),
          width: "",
        },
        {
          prop: "monitorEquipmentChannelCode",
          label: "设备号/通道编码",
          width: "",
        },
        {
          prop: "berthCodes",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_date"),
          width: "170",
        },
      ],
      isPlayBack: true, // 是否支持回放
    };
  },
  created() {
    this.getTenantList();
    // this.tableData.push({
    //   monitorEquipmentChannelId: 1,
    //   monitorEquipmentChannelName: "测试设备",
    //   monitorEquipmentChannelCode: "1111",
    //   monitorEquipmentId: "2222",
    //   parkName: "测试车场",
    //   operationName: "测试运营商",
    //   monitorEquipmentName: "测试nvr",
    //   berthCodes: "1，2，3",
    //   createdTime: "2020*1*02"
    // });
    this.searchData();
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    async videoChange() {
      // if (this.passagewayPage > this.passagewayFlag) {
      //   this.passagewayPage = 1;
      // }
      // await this.getPassagewayList();
      // this.$nextTick(() => {
      //   this.$refs.videoMonitor.clearData();
      // });
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    success() {
      this.searchData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        // await this.getPassagewayList(data);
        this.title = `${data.monitorEquipmentChannelName}`;
        this.passagewayList = [data];
        this.isShowVideoDialog = true;
        this.$nextTick(() => {
          this.$refs.videoMonitor.open();
        });
        // window.open(
        //   "/video.html?" +
        //     data.ipAddress +
        //     "/" +
        //     data.ipPort +
        //     "/" +
        //     data.account +
        //     "/" +
        //     data.password +
        //     "/" +
        //     data.monitorPort,
        //   "查看监控设备"
        // );
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/videoMonitorEdit",
          query: data,
        });
      }
      if (cmd == "c") {
        this.deleteItem(data);
      }
    },
    deleteItem({ monitorEquipmentChannelId }) {
      this.$confirm("此操作将删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/monitorEquipmentChannel/delete/" + monitorEquipmentChannelId)
            .then((res) => {
              if (res.state == 0) {
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    querySearchAsync(queryString, cb) {
      //   this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 100,
            parkName: queryString,
            slaveRelations: "0,1,2",
            // dataSource: 1,
            // areaIds: this.streetId ? this.streetId : this.areaId
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    searchData() {
      this.tableData = [];
      this.loading = true;
      //   this.formInline.areaId = this.streetId ? this.streetId : this.areaId;
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      this.$axios
        .get("/acb/2.0/monitorEquipmentChannel/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.videoMonitor__wrapper {
  .breadcrumb{
    // height: 35px;
  }
  .content{
    // background: #FFFFFF;
    overflow: hidden;
    .pagerWrapper{
      text-align: right;
      margin-top: 18px;
      font-size: 12px;
    }

  }

}
</style>
